<template>
  <div>
    <OfficeVisitFilter v-if="$can('index', 'module_patient.office_visit')" :loading="loading"
      @searchFilter="searchFilter" @filterPage="filterPage" />
    <NurseProcedure v-if="isViewOfficeVisit" />
    <b-card class="table-expand">
      <TitleTable titleTable="Office Visit" iconTable="CheckCircleIcon" />
      <div class="m-1">
        <AppointmentEdit v-if="isEditAppointmentSidebarActive" :is-edit-Appointment-sidebar-active.sync="isEditAppointmentSidebarActive
          " :itemEdit="itemEdit" @editAppointmentSuccess="editAppointmentSuccess" />
        <b-row>
          <b-col cols="12" md="12" class="d-flex align-items-center justify-content-between flex-wrap mb-1 mb-md-0">
            <div>
              <label>{{ $t("Show") }}</label>
              <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>{{ $t("Entries") }}</label>
            </div>

            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
            </div>
          </b-col>
        </b-row>
      </div>

      <LoadingTable v-if="items === null" :columns="fields.length" />
      <b-table v-else :items="items" :fields="fields" striped bordered responsive hover
        class="position-relative text-center" primary-key="id" :sort-by.sync="sortBy" show-empty
        empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
        <template #cell(show)="row">
          <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="4" class="mb-1 d-flex">
                <strong>Patient : </strong>
                <b-link :to="{
                  name: 'patients-view',
                  params: { id: row.item.patients.id },
                }">
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ row.item.patients.name }}
                    {{ row.item.patients.lastname }}
                  </span>
                  <small class="text-muted">{{
                    row.item.patients.email
                  }}</small>
                </b-link>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Visit Date : </strong>{{ row.item.visit_date }}
                {{ row.item.lastname }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>{{ $t("Visit Reason") }}: </strong><span>{{
                  row.item.type_consultations &&
                  row.item.type_consultations.name
                }}</span>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Type Visit : </strong>
                <span v-if="row.item.type_visits">
                  <span :style="`text-decoration: underline ${row.item.type_visits.color}`">{{ row.item.type_visits.name
                    }}</span>
                </span>
                <span v-else>Not Found</span>
              </b-col>
            </b-row>

            <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
              Hide Details
            </b-button>
          </b-card>
        </template>
        <template #cell(patients)="data">
          <b-link :to="isMedical(data.item.office_visit.id)">
            <span>{{ data.item.patients.name }}
              {{ data.item.patients.lastname }}</span>
          </b-link>
        </template>

        <template #cell(type_visits)="data">
          <div class="text-nowrap">
            <b-badge :style="{ 'background-color': data.item.type_visits.color }"
              v-b-tooltip.hover.top="data.item.type_visits.name" class="badge-glow">
              <span class="align-text-top text-capitalize">{{
                data.item.type_visits.prefix
              }}</span>
            </b-badge>
          </div>
        </template>
        <template #cell(nurse)="data">
          <b-media v-if="data.item.office_visit.nurses" vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.office_visit.nurses.avatar" variant="light-primary" />
            </template>
            <b-link :to="{
              name: 'web-settings-users',
              params: {
                id: data.item.office_visit.nurses.id,
              },
            }">
              <span class="font-weight-bold text-nowrap d-flex flex-column align-items-start">
                <span>
                  {{ data.item.office_visit.nurses.name }}
                </span>
                <span>
                  {{ data.item.office_visit.nurses.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>
        <template #cell(oma)="data">
          <b-media v-if="data.item.office_visit.omas" vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.office_visit.omas.avatar" variant="light-primary" />
            </template>
            <b-link :to="{
              name: 'web-settings-users',
              params: {
                id: data.item.office_visit.omas.id,
              },
            }">
              <span class="font-weight-bold d-block text-nowrap d-flex flex-column align-items-start">
                <span>
                  {{ data.item.office_visit.omas.name }}
                </span>
                <span>
                  {{ data.item.office_visit.omas.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge :style="{
              'background-color': data.item.office_visit.visit_statuses.color,
            }" v-b-tooltip.hover.top="data.item.office_visit.visit_statuses.name" class="badge-glow cursor-pointer">
              <span class="align-text-top text-capitalize">{{
                data.item.office_visit.visit_statuses.prefix
              }}</span>
            </b-badge>
          </div>
        </template>
        <template #cell(time)="data">
          <div class="text-nowrap" v-b-tooltip.html.hover.top="getTimesTooltip(data.item.office_visit.visit_status_auditor)
            ">
            <span>{{ data.item.progresstime }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap" v-if="data.item.office_visit.visit_statuses_id < 5">
            <!-- <b-button
              variant="flat-success"
              class="btn-icon btn-sm"
              v-b-tooltip.hover.top="'Add Memberships'"
              @click="addMembership(data.item)"
            >
              <feather-icon icon="CreditCardIcon" size="16" />
            </b-button> -->
            <b-button v-if="$can('nurse_procedure', 'module_patient.office_visit')" :to="{
              name: 'office-visit-view-nurse',
              params: { id: data.item.office_visit.id },
            }" variant="flat-primary" class="btn-icon btn-sm" v-b-tooltip.hover.top="`Nurse's View`">
              <feather-icon icon="EyeIcon" size="16" />
            </b-button>
            <b-button v-if="
              $can('oma_procedure', 'module_patient.office_visit') &&
              data.item.office_visit.visit_statuses_id >= 2
            " :to="{
                name: 'office-visit-view-oma',
                params: { id: data.item.office_visit.id },
              }" variant="flat-primary" class="btn-icon btn-sm" v-b-tooltip.hover.top="data.item.office_visit.visit_statuses_id === 3
                  ? 'Edit View'
                  : `View Visit`
                ">
              <feather-icon icon="ClipboardIcon" size="16" />
            </b-button>
            <b-button v-if="
              $can('confirm_procedures', 'module_patient.office_visit') &&
              data.item.office_visit.recipe
            " :to="{
                name: 'office-visit-recipe',
                params: { id: data.item.office_visit.id },
              }" variant="flat-primary" class="btn-icon btn-sm" v-b-tooltip.hover.top="`Order View`">
              <feather-icon icon="FileTextIcon" size="16" />
            </b-button>
            <b-button v-if="
              $can('order_view', 'module_patient.office_visit') &&
              data.item.office_visit.recipe
            " :to="{
                name: 'office-visit-recipe-oma',
                params: { id: data.item.office_visit.id },
              }" variant="flat-primary" class="btn-icon btn-sm" v-b-tooltip.hover.top="`Order View OMA`">
              <feather-icon icon="CheckSquareIcon" size="16" />
            </b-button>
          </div>
        </template>
      </b-table>
      <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from "bootstrap-vue";

import vSelect from "vue-select";
import GoBack from "@/components/ButtonBack";
import SpinnerLoading from "@/components/SpinnerLoading";
import TitleTable from "@/components/TitleTable.vue";
import ToastNotification from "@/components/ToastNotification";
import PaginationTable from "@/components/PaginationTable";
import LoadingTable from "@/components/LoadingTable.vue";
import scanQr from "@/components/scanQr.vue";

import AppointmentAdd from "@/views/appointment/appointment-actions/AppointmentAdd.vue";
import AppointmentEdit from "@/views/appointment/appointment-actions/AppointmentEdit.vue";
import OfficeVisitFilter from "./OfficeVisitFilter.vue";
import NurseProcedure from "@/views/office-visit/office-visit-review/NurseProcedure.vue";
import axiosA from "@/services/office-visit";

export default {
  components: {
    BTable,
    BButton,
    scanQr,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
    BMedia,
    BAvatar,
    GoBack,
    SpinnerLoading,
    ToastNotification,
    PaginationTable,
    LoadingTable,

    vSelect,
    TitleTable,
    AppointmentAdd,
    AppointmentEdit,
    OfficeVisitFilter,
    NurseProcedure,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      facilityGlobal: this.$facilityGlobal,
      fields: [
        { key: "show", tdClass: "th-class-adjusted" },
        { key: "id", sortable: true, tdClass: "th-class-adjusted" },
        {
          key: "patients",
          label: "Patient",
          sortable: true,
          tdClass: "th-class-adjusted",
        },
        {
          key: "patients.date_birth",
          label: "DOB",
          sortable: true,
          class: "th-class-adjusted-appointment",
        },
        {
          key: "visit_date",
          label: "DOS",
          sortable: true,
          tdClass: "th-class-adjusted",
        },
        {
          key: "type_visits",
          label: "Type Visit",
          sortable: true,
          tdClass: "th-class-adjusted",
        },
        {
          key: "office_visit.room",
          label: "Room",
          sortable: true,
          tdClass: "th-class-adjusted",
        },
        {
          key: "nurse",
          label: "Nurse",
          sortable: true,
          tdClass: "th-class-adjusted",
        },
        {
          key: "oma",
          label: "OMA",
          sortable: true,
          tdClass: "th-class-adjusted",
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          tdClass: "th-class-adjusted",
        },
        {
          key: "time",
          label: "Time",
          sortable: true,
          tdClass: "th-class-adjusted",
        },
        { key: "actions", tdClass: "th-class-adjusted" },
      ],
      items: null,
      perPageOptions: [10, 25, 50, 100],
      statusOptions: [
        { text: "Active", value: "true" },
        { text: "Inactive", value: "false" },
      ],
      perPage: 50,
      loading: false,
      isSortDirDesc: true,
      isConnected: false,
      sortBy: "id",
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      checkCodeQrModal: false,
      userAppointmentId: 0,
      currentPage: {
        page: 1,
      },
      totalUsers: 0,
      isAddNewAppointmentSidebarActive: false,
      isEditAppointmentSidebarActive: false,
      isViewOfficeVisit: false,
      filtro: false,
      officeVisitFilter: null,
      cleanQuery: true,
      codeQr: null
    };
  },
  created() {
    const channel = this.$pusher.subscribe(
      `officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`
    );
    channel.bind("officeVisitEvent", ({ event }) => {
      if (!this.filtro) {
        if (event.type === "office_visiit" || event.type === "appointment") {
          this.getAppointments();
        }
      }
    });
    this.getAppointments();
  },
  destroyed() {
    this.$pusher.unsubscribe(
      `officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`
    );
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getAppointments();
      } else {
        this.searchFilter(this.officeVisitFilter);
      }
    },
    "$variableGlobal.facility": function (value) {
      this.$refs.toast.success("Has successfully moved to another facility");
      this.getAppointments();

      // pusher
      this.$pusher.unsubscribe(
        `officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`
      );
      const channel = this.$pusher.subscribe(
        `officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`
      );
      channel.bind("officeVisitEvent", ({ event }) => {
        if (!this.filtro) {
          if (event.type === "office_visiit" || event.type === "appointment") {
            this.getAppointments();
          }
        }
      });
    },
  },
  methods: {
    scanPromotion(id) {
      if (this.checkCodeQrModal) {
        this.codeQr = null
      }
      this.userAppointmentId = id
      this.checkCodeQrModal = !this.checkCodeQrModal;
    },
    getAppointments() {
      axiosA
        .officeVisitList(this.perPage)
        .then(({ total, current_page, ...res }) => {
          this.items = res.data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    resolveUserStatusVariant(status) {
      if (status) return "success";
      return "danger";
    },
    getTimesTooltip(item) {
      let times = "";
      item.forEach((time) => {
        times += `${time.prefix} - ${time.time} <br>`;
      });
      return times;
    },
    searchFilter(value) {
      this.loading = true;
      this.officeVisitFilter = value;
      axiosA
        .officeVisitFilter(this.perPage, value)
        .then(({ registro: { data, total, current_page, ...res } }) => {
          this.loading = false;
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    filterPage(value) {
      this.filtro = value;
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosA
          .officeVisitListPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosA
          .officeVisitFilterPagination(
            this.perPage,
            page,
            this.officeVisitFilter
          )
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    isMedical(id) {
      const rolMain = JSON.parse(localStorage.getItem("userData")).roles[0];
      if (rolMain.id === 4) {
        const isAvalible = this.$ability.can(
          "nurse_procedure",
          "module_patient.office_visit"
        );
        if (isAvalible) {
          return {
            name: "office-visit-view-nurse",
            params: { id: id },
          };
        }
        return null;
      }
      const isAvalible = this.$ability.can(
        "oma_procedure",
        "module_patient.office_visit"
      );
      if (isAvalible) {
        return {
          name: "office-visit-view-oma",
          params: { id: id },
        };
      }
      return null;
    },
  },
};
</script>
<style lang="scss">
.th-class-adjusted {
  padding: 0.6rem 0.1rem !important;
}

.table-expand .card-header {
  padding: 0 1rem !important;
}
</style>
<style scoped>
.btn-icon .btn-icon-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-img {
  filter: brightness(0) saturate(100%) invert(56%) sepia(12%) saturate(1910%) hue-rotate(171deg) brightness(85%) contrast(85%);
}
</style>

import { userAxios } from '../index'

const officeVisitList = async (perPage) => {
    try {
        return await userAxios.get(`appointment/appointment-insurance-calculate/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const officeVisitListPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`appointment/appointment-insurance-calculate/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const officeVisitFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`appointment/appointment-insurance-calculate/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const officeVisitFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`appointment/appointment-insurance-calculate/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureOma = async (id, data) => {
    try {
        return await userAxios.post(`insurance-appointment/show/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    officeVisitList,
    officeVisitListPagination,
    officeVisitFilter,
    officeVisitFilterPagination,
    procedureOma,
}
<template>
  <b-card class="mb-1" no-body>
    <b-card-header class="pb-50 cursor-pointer" @click="visible = !visible">
      <div class="d-flex justify-content-between">
        <h5>{{ $t("Filters") }}</h5>
        <feather-icon :icon="visible ? 'ArrowDownIcon' : 'ArrowUpIcon'"/>
      </div>
    </b-card-header>
    <b-collapse v-model="visible">
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Patient") }}</label>
          <b-form-input
            autofocus
            v-model.trim="filters.patient_name"
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t('Form.Type User') }}</label>
          <v-select
            v-model="filters.user_type"
            class="w-100"
            :options="TypeUserOptions"
            :reduce="(value) => value.value"
            label="name"
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{$t('Types of visits')}}</label>
          <v-select
            v-model="filters.type_visits_id"
            class="w-100"
            :options="typeVisitOptions"
            :reduce="(value) => value.id"
            label="name"
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{$t('Form.Room')}}</label>
          <v-select
            v-model="filters.room"
            class="w-100"
            :options="roomOptions"
            label="name"
            @keyup.enter="searchFilter"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Nurse</label>
          <b-form-input
            autofocus
            v-model.trim="filters.nurse"
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Oma</label>
          <b-form-input
            autofocus
            v-model.trim="filters.oma"
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t('Form.Status') }}</label>
          <v-select
            v-model="filters.visit_statuses_id"
            class="w-100"
            :options="visitStatusOptions"
            :reduce="(value) => value.id"
            label="name"
            @keyup.enter="searchFilter"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t('Form.Start Date') }}</label>
          <flat-pickr
            v-model="filters.visit_date"
            :config="fConfig"
            class="form-control"
            placeholder="MM-DD-YYYY"
            type="date"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t('Form.End Date') }}</label>
          <flat-pickr
            v-model="filters.date_end"
            :config="uConfig"
            class="form-control"
            placeholder="MM-DD-YYYY"
            type="date"
          />
        </b-col>
        <b-col cols="12" class="mb-md-0 mb-2" align="end">
          <b-button variant="warning" @click="resetFilter" class="mr-1">
            {{ $t("Clear") }}
          </b-button>
          <b-button variant="primary" @click="searchFilter" :disabled="loading">
            <span v-if="!loading">{{ $t("Search") }}</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BCollapse
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import SpinnerLoading from "@/components/SpinnerLoading";

import axiosLC from "@/services/admin/consults/visits";
import axiosS from "@/services/admin/consults/visitStatus";
import { fromConfig, untilConfig } from "@/mixins/configFormatCal";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    BCollapse,
    vSelect,
    flatPickr,
    SpinnerLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stateOptions: [],
      typeVisitOptions: [],
      visitStatusOptions: [],
      filters: {
        patient_name: null,
        user_type: null,
        type_visits_id: null,
        room: null,
        nurse: null,
        oma: null,
        visit_statuses_id: null,
        visit_date: null,
        date_end:null
      },
      statusOptions: [
        { name: "Active", value: "true" },
        { name: "Inactive", value: "false" },
      ],
      TypeUserOptions: [
        { name: "Established", value: "established" },
        { name: "New", value: "new" },
      ],
      roomOptions: [1, 2, 3, 4, 5],
      fConfig: fromConfig({dateFormat: "m-d-Y H:i:S"}),
      uConfig: untilConfig({dateFormat: "m-d-Y H:i:S"}),
      visible: false
    };
  },
  mounted() {
    this.getCategory();
    this.getCategoryStatus();
  },
  methods: {
    searchFilter() {
      const filter = this.filters;
      let cont = 0;
      for (const iterator in filter) {
        if (this.filters[iterator] == "" || this.filters[iterator] == null) {
          cont++;
        }
      }
      if (Object.keys(filter).length == cont) this.$emit("filterPage", false);
      else this.$emit("filterPage", true);

      this.$emit("searchFilter", JSON.parse(JSON.stringify(this.filters)));
    },
    resetFilter() {
      const filter = JSON.parse(JSON.stringify(this.filters));
      for (const iterator in filter) {
        this.filters[iterator] = null;
      }
    },
    getCategory() {
      axiosLC.visitList(10).then(({ data }) => {
        this.typeVisitOptions = data;
      });
    },
    getCategoryStatus() {
      axiosS.visitStatusList(50).then(({ data }) => {
        this.visitStatusOptions = data;
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>



<template>
    <component :is="officeVisitType[typeClinic]"></component>
</template>

<script>
import TableOfficeVisit from './TableOfficeVisit.vue';
import TableOfficeVisitInsurance from './insurance/TableOfficeVisitInsurance.vue';

export default {
    components: {
        TableOfficeVisit,
        TableOfficeVisitInsurance,
    },
    mounted(){
        const facilityInUse = this.$variableGlobal.isMedicalCenter;
        this.typeClinic = facilityInUse ? 2 : 1;
    },
    data(){
        return {
            typeClinic: null,
            officeVisitType: {
                1: 'TableOfficeVisit',
                2: 'TableOfficeVisitInsurance'
            }
        }
    },
    watch: {
        "$variableGlobal.isMedicalCenter": function (value) {
            this.typeClinic = value ? 2 : 1;
        },
    }
}
</script>